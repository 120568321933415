<template>
    <div>
        Bonjour!
    </div>
</template>

<script>
    export default {
        name: "detail-client",
        data() {
            return {

            }
        },
        methods: {

        },
        props: [],
        computed: {

        }
    }
</script>

<style scoped>

</style>
