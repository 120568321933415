<template>
    <div>

    <div class="row">
        <div class="col-8 offset-2">
            <form action="" @submit.prevent="rechercher" class="needs-validation">

                <div class="form-group">
                    <input type="text" id="rechercheD" v-model="donnees" name="donnees" class="form-control" required placeholder="Veuillez saisir le nom et prénom du client">
                </div>
                <div>
                    <input type="submit" class="btn btn-primary btn-block" value="Rechercher">
                </div>
            </form>
        </div>
    </div>

        <div class="col-8 offset-2" v-if="loading">
            <div class="loader0">
                <div style="margin-top: 30px; text-align: center "><img src="../../../Admin-template/img/ajax-loader.gif" alt="" width="100" height="100"></div>
<!--                <div style="font-size: 20px; padding-top: 2px; text-align: center; color: #0c5460">En cours de traitement ...</div>-->

            </div>
        </div>

  <div style="margin-top: 30px" v-if="resultat.length > 0 && resultat[0]!=null">

      <b-table
              id="my-table"
              :items="resultat"
              :per-page="perPage"
              :current-page="currentPage"
                :fields="fields"
              small
      >
          <template v-slot:cell(actions)="row">
              <b-button size="sm" class="btn btn-primary" @click="row.toggleDetails">
                   Detail
              </b-button>
          </template>

          <template v-slot:row-details="row">
              <b-card>

                  <div class="row mb-2"  v-for="(value, key) in row.item" :key="key">
                      <div class="col-4" v-if="key==='code'">Code: </div>
                      <div class="col-4" v-if="key==='nom'">Nom: </div>
                      <div class="col-4" v-if="key==='dateNaissance'">Date naissance: </div>
                      <div class="col-4" v-if="key==='lieuNaissance'">Lieu naissance: </div>
                      <div class="col-4" v-if="key==='telephone'">Téléphone: </div>
                      <div class="col-4" v-if="key==='pieceIdentite'">Pièce identité: </div>
                      <div class="col-4" v-if="key==='situationGeo'">Situation Géographique: </div>
                      <div class="col-4" v-if="key==='dateChargement'">Date chargement: </div>
                      <div class="col-4" v-if="key==='sfd'">SFD: </div>
                      <div class="col-4" v-if="key==='montant'">Montant: </div>
                      <div class="col-4" v-if="key==='jour_retard'">Jour retard: </div>
                      <div class="col-4" v-if="key==='codeCredit'">Code crédit: </div>
                      <div class="col-4" v-if="key==='type_credit'">Type crédit: </div>
                      <div class="col-4" v-if="key==='dateDeblocage'">Date déblocage: </div>
                      <div class="col-4" v-if="key==='montant_debloque'">Montant débloqué: </div>
                      <div class="col-8" v-if="value!=true && key!=='identifiant'">{{value}}</div>

                  </div>

                  <div class="col-md-2 offset-9">

                      <a :href="imprime+row.item['identifiant']" class="btn btn-info">imprimer  </a>
                  </div>

              </b-card>
          </template>
      </b-table>



      <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
      ></b-pagination>



  </div>
    <div class="col-8 offset-2 alert alert-warning alert-dismissible" style="margin-top: 30px; font-size: 26px; text-align: center" v-else-if="resultat[0]===null">
        Désolé! Aucun resultat trouvé.
    </div>
<!--        <router-link to="detail">ccccc</router-link>-->
<!--        <router-view></router-view>-->
    </div>


</template>

<script>

    export default {
        name: "recherche",
        data: function () {
            return {
                imprime: "/c/export-donnees-client/",
                donnees:'',
                resultat:[],
                loading: false,
                perPage: 10,
                currentPage: 1,
                fields: [
                    { key: 'sfd', label: 'SFD', sortable: true, sortDirection: 'desc' },
                    { key: 'dateChargement', label: 'Date chargement', sortable: true, class: 'text-center' },
                    { key: 'nom', label: 'Nom et prénom', sortable: true, class: 'text-center' },
                    { key: 'telephone', label: 'Téléphone', sortable: true, sortDirection: 'desc' },
                   // { key: 'type_credit', label: 'Type crédit', sortable: true, sortDirection: 'desc' },
                   // { key: 'jour_retard', label: 'Nbre jour retard', sortable: true, sortDirection: 'desc' },
                    { key: 'montant', label: 'Montant', sortable: true, sortDirection: 'desc' },
                    { key: 'actions', label: 'Actions' }
                ]
            }
        },
        methods: {
            rechercher : function () {
                this.loading=true
                this.$http.post('/c/recherhe-des-dossiers',{
                    donnees:this.donnees
                }).then((response)=>{

                    if (response.status==200) {
                        this.resultat=response.data;
                    }

                },(response)=>{
                    console.log('erreur',response)
                }).then( _ => {
                    this.loading=false
                })
            },



        },
        computed: {
            rows() {
                return this.resultat.length
            }
        }
    }
</script>

<style scoped>
    /*.loader {*/
    /*    background-image: url("../../../Admin-template/img/ajax-loader.gif");*/
    /*    content: "Traitement en cours ...";*/
    /*    background-repeat: no-repeat;*/
    /*}*/
</style>
