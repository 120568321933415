import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import detailClient from '../components/detail-client'


export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/detail-client',
      name: 'detail',
      component: detailClient
    }
  ]
})

