import Vue from "vue";


//var VueResource = require('vue-resource');

import VueResource from "vue-resource";
Vue.use(VueResource);

import router from './router';

//import Paginate from "vuejs-paginate";
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

//Vue.component('paginate',Paginate);

import recherche from "./components/recherche";

Vue.http.options.root = 'http://127.0.0.1:8000';
// Vue.http.headers.common['Authorization'] = 'Basic YXBpOnBhc3N3b3Jk';
Vue.config.productionTip = false;
Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;



new Vue({
    el: '#app',
    delimiters: ['${','}'],
    data: {
        message:'Bonjour à tous!'
    },
    components: {
        recherche
    },
    router


})
